import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { delateClient, getDatos, updateAgente, updatePrivado } from '../api/getUser';
import { customSwall } from "./CustomSwal"
import Spiner from './Spiner';
import Icons from "./Icons"
import { urlServer } from "../config/config"

function ItemCliente({ cliente, modificar, eliminar }) {
    const user = JSON.parse(localStorage.getItem('userData'))
    const [privado, setPrivado] = useState(parseInt(cliente.privado))
    const [agenteCliente, setAgenteCliente] = useState(parseInt(cliente.agente))
    const navigate = useNavigate();
    const hayObservacion = cliente.observaciones !== ''
    const tieneInfoLlamada = parseInt(cliente.cont_llamada)
    const tieneInfoCorreo = parseInt(cliente.cont_correo)
    const abreCorreos = parseInt(cliente.lee_correos)

    const { mutate, isLoading } = useMutation(updatePrivado, {
        onSuccess: (data) => {
            if (!data.ok) {
                setPrivado(!privado)
            }
        }
    })

    const { mutate: fetchAsiganarAgente, isLoading: asignando } = useMutation(updateAgente, {
        onSuccess: (data) => {
            if (data.ok) {
                setAgenteCliente(parseInt(data.agente))
            }
        }
    })

    const {  data: datosAgentes = [], isLoading: recuperandoAgentes } = useQuery(["usuariosAgents"], () => getDatos('/api/get-data/usuarios', { token: user.token }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    function mostrarInfo(id, req) {
        customSwall.fire({
            title: 'Recuperando...',
            html: 'Espera...',
            animation: false,
            backdrop: false,
            didOpen: () => {
                customSwall.showLoading()
                let formData = new FormData()
                formData.append('token', user.token)
                formData.append('cliente', id)
                formData.append('req', req)
                fetch(`${urlServer}/api/base/clientes/info`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        customSwall.fire({
                            title: data.title,
                            html: data.html,
                            width: '800px',
                            showConfirmButton: true,
                        })
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No se pudo recuperar la informacion',
                            showConfirmButton: true,
                            timer: 2000
                        })
                    })
            }
        })
    }

    function mostrarTelefonos(correo, telefonos) {
        let textoTelefonos = JSON.parse(telefonos).join(', ');
        customSwall.fire({
            title: `Telefonos de: ${correo}`,
            html: textoTelefonos,
            width: '800px',
            showConfirmButton: true,
        })
    }

    function showEliminar() {
        customSwall.fire({
            icon: 'question',
            title: `Eliminar Cliente?`,
            html: `<p>Estas seguro? esta acción no se puede revertir</p><br><br><p><b>Eliminar: </b>${cliente.correo}</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar'
        }).then(response => {
            if (response.isConfirmed) {
                eliminar({ token: user.token, cliente: cliente.id })
            }
        })
    }

    function actualizarPrivado(e) {
        setPrivado(e.target.checked)
        mutate({ token: user.token, cliente: cliente.id, privado: e.target.checked })
    }

    function asiganarAgente(e) {
        setPrivado(e.target.checked)
        fetchAsiganarAgente({ token: user.token, cliente: cliente.id, agente: e.target.value })
    }

    return (<>
        <tr>
            <td>{cliente.id}</td>
            <td>{cliente.nombre}</td>
            <td>{cliente.apellido}</td>
            <td>{cliente.empresa}</td>
            <td>{cliente.cargo}</td>
            <td>{cliente.correo}</td>
            <td className="td-acciones">
                {(JSON.parse(cliente.telefonos).length > 1) ? <button title='Editar' onClick={() => mostrarTelefonos(cliente.correo, cliente.telefonos)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" /></svg>
                </button> : (
                    JSON.parse(cliente.telefonos).length === 1 ? JSON.parse(cliente.telefonos)[0] : <></>
                )}
            </td>
            <td {...(tieneInfoLlamada && { title: 'Click para ver detalles de llamada', onClick: () => mostrarInfo(cliente.id, 'cont_llamada'), style: { cursor: "pointer" } })}>
                <Icons req={tieneInfoLlamada ? 'check' : 'clock'} fill={tieneInfoLlamada ? "var(--verde)" : "var(--gris)"} />
            </td>
            <td {...(tieneInfoCorreo && { title: 'Click para ver los correos enviados', onClick: () => mostrarInfo(cliente.id, 'cont_correo'), style: { cursor: "pointer" } })}>
                <Icons req={tieneInfoCorreo ? 'check' : 'clock'} fill={tieneInfoCorreo ? "var(--verde)" : "var(--gris)"} />
            </td>
            <td {...(hayObservacion && { title: 'Click para ver las observaciones', onClick: () => mostrarInfo(cliente.id, 'observaciones'), style: { cursor: "pointer" } })}>
                <Icons req={hayObservacion ? 'info' : 'circulo'} fill={hayObservacion ? "var(--naranja)" : "var(--gris)"} />
            </td>
            <td>
                <Icons req={abreCorreos ? 'check' : 'clock'} fill={abreCorreos ? "var(--verde)" : "var(--gris)"} />
            </td>
            <td className="td-acciones">
                <button title='Editar' onClick={() => modificar(cliente.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                </button>
                <button title='Enviar Correo' onClick={() => navigate(`/enviar-correo?send=${cliente.correo}&name=${cliente.nombre}&last_name=${cliente.apellido}&empresa=${cliente.empresa}`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg>
                </button>
                {/*<button title='Cotizar' onClick={ () => navigate(`/Cotizaciones/nueva-cotizacion?email=${ cliente.correo }&name=${ cliente.nombre }&last_name=${ cliente.apellido }&empresa=${ cliente.empresa }`) }>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                </button>*/}
                {user.role === 'admin' && <button title='Eliminar contacto' onClick={showEliminar} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                </button>}
            </td>
            {user.role === 'admin' && <>
                <td>
                    <select className='select-cliente-agente' value={agenteCliente} onChange={e => asiganarAgente(e)} disabled={ asignando }>
                        <option value="0">Sin Asignar</option>
                        {
                            datosAgentes?.map((agente, index) => <option key={index} value={agente.id}>{agente.correo}</option>)
                        }
                    </select>
                </td>
                <td>
                    <label className="switch">
                        <input type="checkbox" checked={privado} onChange={(e) => actualizarPrivado(e)} disabled={isLoading} />
                        <span className="slider round"></span>
                    </label>
                </td>
            </>}
        </tr>
    </>)
}

export default function ({ clientes, modificar }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(delateClient, {
        onSuccess: (data) => {
            if (data.ok) {
                customSwall.fire({
                    icon: 'success',
                    title: `Cliente eliminado!`,
                    text: `Los datos fue eliminado correctamente`,
                    showConfirmButton: true,
                }).then(response => {
                    queryClient.invalidateQueries(["clientes"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'No se pudo completar la acción, contacta a soporte',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    return (<>
        {isLoading && <Spiner />}
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Empresa</th>
                    <th>Cargo</th>
                    <th>Correo</th>
                    <th title="Teléfonos">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16" width="16"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                    </th>
                    <th title="Llamada realizada">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" /></svg>
                    </th>
                    <th title="Correo enviado">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z" /></svg>
                    </th>
                    <th title="Observaciones">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" /></svg>
                    </th>
                    <th title="Lee correos">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z" /></svg>
                        &nbsp;
                    </th>
                    <th>Acciones</th>
                    {user.role === 'admin' && <>
                        <th style={{ width: '40px' }}>Agente</th>
                        <th title='Cliente privado?' style={{ width: '40px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                        </th>
                    </>}
                </tr>
            </thead>
            <tbody>
                {
                    clientes?.map((cliente, key) => {
                        return (
                            <ItemCliente key={`cliente_${key}`} cliente={cliente} modificar={modificar} eliminar={(datos) => mutate(datos)} />
                        )
                    })
                }
            </tbody>
        </table>
    </>)
}