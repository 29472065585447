import { Routes, Route, Navigate } from 'react-router-dom';
import { validToken } from './api/getUser'; 
import ProtectedRoutes from './components/ProtectedRoutes'
import Inicio from './pages/Inicio';
import MainLogin from "./components/MainLogin";
import './assets/css/index.css'
import { useEffect, useState } from 'react';
import EnviarCorreo from './pages/EnviarCorreo';
import BaseDatos from './pages/BaseDatos';
import ListaDeProductos from './pages/ListaDeProductos';
import Cotizaciones from './pages/Cotizaciones';
import AddCotizacion from './pages/AddCotizacion';
import Campanas from './pages/Campanas';

function App() {

  const [ logeado, setLogeado ] = useState(false)
  const user = !!localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null

  useEffect(() => {
    if (user === null) {
      setLogeado(false)
      return
    }
    if (!validToken(user.token)) {
        localStorage.removeItem('userData');
        setLogeado(false)
        return
    }
    if (user.role !== 'admin') {
      disableCopy()
    }
    setLogeado(true)
  }, [])

  function disableCopy() {
    document.addEventListener("copy", function(event){
      // Change the copied text if you want
      event.clipboardData.setData("text/plain", "No se permite copiar en esta página web");
      
      // Prevent the default copy action
      event.preventDefault();
    }, false);
    
    document.addEventListener("cut", function(event){
      // Change the copied text if you want
      event.clipboardData.setData("text/plain", "No se permite copiar en esta página web");
      
      // Prevent the default copy action
      event.preventDefault();
    }, false);
  }

  return (
    <Routes>
        <Route index element={ logeado ? <ProtectedRoutes><Inicio/></ProtectedRoutes> : <MainLogin/> }/>
        <Route path='/inicio' element={
          <ProtectedRoutes>
            <Inicio/>
          </ProtectedRoutes>
        } />
        <Route path='/enviar-correo' element={
          <ProtectedRoutes>
            <EnviarCorreo/>
          </ProtectedRoutes>
        } />
        <Route path='/base-datos' element={
          <ProtectedRoutes>
            <BaseDatos/>
          </ProtectedRoutes>
        } />
        <Route path='/lista-de-productos' element={
          <ProtectedRoutes>
            <ListaDeProductos/>
          </ProtectedRoutes>
        } />
        <Route path='/Cotizaciones' element={
          <ProtectedRoutes>
            <Cotizaciones/>
          </ProtectedRoutes>
        } />
        <Route path='/Cotizaciones/nueva-cotizacion' element={
          <ProtectedRoutes>
            <AddCotizacion/>
          </ProtectedRoutes>
        } />
        <Route path='/campanas' element={
          <ProtectedRoutes>
            <Campanas/>
          </ProtectedRoutes>
        } />
        <Route path='*' element={ <Navigate replace to="/"/> } />
    </Routes>
  )
}

export default App;
